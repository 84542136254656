import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { base_url } from '../utils/base_url';
import { token } from '../utils/axiosconfig';
import { useTranslation } from './TranslationContext';

const BlogDataContext = createContext();

export function BlogDataProvider({ children }) {
  const [blogData, setBlogData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { translate, changeLanguage, currentLanguage, Language } =
    useTranslation();

  const fetchBlogData = async () => {
    try {
      const config = {
        headers: {
          'Accept-Language': currentLanguage,
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      const Blogresponse = await axios.get(
        `${base_url}/api/posts?published=true`,
        config
      );
      const Pageresponse = await axios.get(`${base_url}/api/pages`, config);

      const Serviceresponse = await axios.get(
        `${base_url}/api/services?channels=true`,
        config
      );
      const Settingresponse = await axios.get(
        `${base_url}/api/settings`,
        config
      );

      setBlogData(Blogresponse.data);
      setServiceData(Serviceresponse.data);
      setPageData(Pageresponse.data);
      setSettingData(Settingresponse.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, [currentLanguage]);

  return (
    <BlogDataContext.Provider
      value={{ blogData, serviceData, pageData, settingData, loading }}
    >
      {children}
    </BlogDataContext.Provider>
  );
}

export function useBlogData() {
  return useContext(BlogDataContext);
}
