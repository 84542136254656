export const token = '34|G38cv1WIoguRzR7Bry20wwWus6e8icrT6YaHdZbT3ee9af1e';

export const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
  'Accept-Language': 'en',
};
